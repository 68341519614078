.paymentPay {
  height: calc(100vh - 52px);
  padding: 24px;
  background-color: rgb(243, 244, 249);
}

.paymentPay__header {
  width: 100%;
  margin-bottom: 24px;
}
.paymentPay__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(180px, auto);
  height: 50%;
  gap: 20px;
}
.box__header {
  line-height: 1;
  margin-bottom: 24px;
  color: rgb(18, 31, 67);
  font-size: 16px;
  font-weight: 700;
}

.box__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1.5;
  margin-bottom: 12px;
  color: rgb(18, 31, 67);
  font-size: 14px;
}
.paymentBox1 {
  grid-column: span 1;
  grid-row: span 2;
}

.paymentBox2 {
  grid-column: span 1;
  grid-row: span 1;
}
