.carrierMaterialDelete {
  position: relative;
  padding: 82px 32px 32px 32px;
}

.carrierMaterialDelete__iconC {
  position: absolute;
  top: -60px;
  left: calc(50% - 60px);
  display: flex;
  justify-content: center;
  padding: 40px;
  border: 10px solid rgb(255, 255, 255);
  border-radius: 50%;
  background-color: rgb(255, 49, 111);
}
.carrierMaterialDelete__icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  color: rgb(255, 255, 255);
  font-size: inherit;
  font-size: 50px;
  transform: scale(2.5);
}
.carrierMaterialDelete__title {
  margin-bottom: 24px;
  color: rgb(140, 163, 186);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.carrierMaterialDelete__id {
  margin-top: 24px;
  color: rgb(46, 46, 46);
}

.carrierMaterialDelete__buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
