.navbar {
  display: flex;
  align-items: center;
  height: 52px;
  border-bottom: 0.5px solid rgb(231, 288, 288);
  color: rgb(85, 85, 85);
  font-size: 14px;
}
.navbar__wrapper {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  padding: 20px;
}

.navbar__items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 30%;
}

.navbar__item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.navbar__item--timer {
  flex: 3;
}
.navbar__icon {
  font-size: 20px;
}
.navbar__avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgb(85, 85, 85);
  color: rgb(204, 204, 204);
}
.navbar h2 {
  margin-right: 10px;
  font-size: 20px;
}

.navbar button {
  padding: 2px 20px;
  margin-left: 10px;
  border: 0;
  outline: 0;
  border-radius: 10px;
  font-size: 18px;
  cursor: pointer;
}
