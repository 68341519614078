@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");

*,
*::before {
  box-sizing: border-box;
  line-height: 1.5;
  padding: 0;
  margin: 0;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
}

.App {
  width: 100%;
  height: 100vh;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}
