.basicTable {
  display: table;
  width: 100%;
  min-width: 900px;
  border-spacing: 0px 10px;
  border-collapse: separate;
  text-indent: initial;
  border-color: rgb(128, 128, 128);
}

.basicTable__head {
  display: table-header-group;
  border-color: transparent;
}

.basicTable__body {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
  text-size-adjust: 100%;
}

.basicTable__bodyRow {
  display: table-row;
  line-height: 1.5rem;
  padding: 16px;
  outline: 0px;
  background-color: rgb(255, 255, 255);
  color: inherit;
  transition: background-color 0.3s ease 0s;
  vertical-align: middle;
}
.basicTable__bodyRow:hover {
  background-color: rgb(245, 245, 245);
}

.basicTable__dataRowHeader {
  display: table-cell;
  line-height: 1.43;
  padding: 16px;
  border-bottom: 0px;
  color: rgb(18, 31, 67);
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  transition: color 0.3s ease 0s;
  vertical-align: inherit;
}

.basicTable__dataRowHeaderEdit {
  text-align: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.basicTable__dataRowHeaderIcons {
  display: flex;
  justify-content: center;
}

.basicTable__dataRowHeaderButton {
  position: relative;
  overflow: visible;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  padding: 8px;
  margin: 0px;
  border: 0px;
  outline: 0px;
  border-radius: 50%;
  background-color: transparent;
  color: rgba(18, 31, 67, 0.54);
  font-family: Montserrat, sans-serif;
  font-size: 1.5rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  vertical-align: middle;
  appearance: none;
}

.basicTable__dataRowHeaderButton:hover {
  background-color: rgba(18, 31, 67, 0.04);
}

.basicTable__dataRowHeaderIcon {
  display: inline-block;
  width: 1em;
  height: 1em;
  color: rgb(140, 163, 186);
  font-size: 19px;
  transition: color 0.3s ease 0s;
  user-select: none;
  fill: currentcolor;
  flex-shrink: 0;
}

.table__pills {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  line-height: 1.6;
  padding: 0.2rem 1rem;
  border-radius: 40px;
  color: rgb(255, 255, 255);
  font-size: 13px;
  font-weight: 500;
}
.table__pills--paid {
  background-color: rgb(39, 206, 136);
}
.table__pills--unpaid {
  background-color: rgb(255, 49, 111);
}

.table__firstColumn {
  color: rgb(18, 31, 67);
  font-size: 13px;
  font-weight: 800;
}

@media only screen and (max-width: 1366px) {
  .basicTable__dataRowHeader {
    line-height: 1.43;
    padding: 16px 0px;
    font-size: 11px;
  }
  .table__pills {
    width: 80%;
    padding: 0.2rem 0.4rem;
    font-size: 11px;
  }
  .basicTable__dataRowHeaderButton {
    padding: 4px;
    font-size: 1.1rem;
  }
}
