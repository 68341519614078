.popup {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup__container {
  width: 100%;
  max-width: 700px;
  max-height: 600px;
  border-radius: 4px;
  background-color: rgb(240, 255, 255);
}
