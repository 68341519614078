.dashboard {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(180px, auto - 20px);
  height: calc(100vh - 52px);
  gap: 20px;
  padding: 24px;
  background-color: rgb(243, 244, 249);
}

.box {
  padding: 34px 24px 12px 24px;
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(95, 116, 141, 0.03) 0px 2px 1px -1px,
    rgba(95, 116, 141, 0.04) 0px 1px 1px 0px,
    rgba(95, 116, 141, 0.08) 0px 1px 3px 0px;
  background-color: rgb(255, 255, 255);
}

.box1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Dodane, aby rozdzielić przestrzeń */
  grid-column: span 1;
  grid-row: span 3;
}
.box1__header,
.box1__footer {
  font-size: 14px;
}
.box1__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Umożliwia rozciągnięcie kontenera */
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
}
.box1__list {
  width: 100%;
  margin-top: 24px;
}
.box1__listElement {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  background-color: hsl(0, 100%, 50%) 0000;
  font-size: 14px;
}

.box1__listText {
  flex: 2;
}
.box1__listIcon {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}
.box1__listText--name {
  display: flex;
  justify-content: flex-start;
  padding-right: 10px;
  font-size: 0.9em;
  font-weight: 700;
}
.box1__listText--data {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  padding-right: 10px;
  font-size: 0.9em;
}
.box1__listText--date {
  flex: 1;
  font-size: 0.9em;
  text-align: center;
}
.box4 {
  grid-column: span 1;
  grid-row: span 3;
}

.box7 {
  grid-column: span 2;
  grid-row: span 2;
  display: flex;
  flex-direction: column;
}

.box7__container {
  height: 500px;
}
.box8__header {
  margin-bottom: 8px;
}

.box8__subHeader {
  margin-bottom: 16px;
  color: rgb(47, 67, 101);
  font-size: 24px;
  font-weight: 700;
}

.box8__percentContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.box8__percentIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 5px;
}
.box8__percentIcon.positive {
  background-color: rgba(39, 206, 136, 0.1);
}
.box8__percentIcon.negative {
  background-color: rgba(255, 107, 147, 0.1);
  transform: rotate(180deg);
}

.box8__percentIcoEl {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}

.box8__percentIcoEl.positive,
.box8__percentValue.positive {
  color: rgb(39, 206, 136);
}
.box8__percentIcoEl.negative,
.box8__percentValue.negative {
  color: rgb(255, 107, 147);
}
.box8__percentValue {
  line-height: 1.6;
  font-size: 13px;
  font-weight: 600;
}
@media only screen and (max-width: 1366px) {
  .box7__container {
    height: 360px;
  }
}
