.contractNumber {
  width: 100%;
  min-width: 0px;
  line-height: 1.4375em;
  padding: 18px;
  color: rgb(18, 31, 67);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
}
