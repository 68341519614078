.payment__filter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 12px;
  flex-wrap: wrap;
}
.payment__filterStatus {
  display: flex;
  justify-content: flex-start;
  flex: 1;
  gap: 24px;
}
.payment__filterStatusItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  transition: border-bottom 0.3s ease-in;
}

.payment__filterStatusItem.active {
  border-bottom: 3px solid rgb(36, 153, 239);
}
.payment__filterStatusItem > h6 {
  font-size: 15px;
  font-weight: 600;
}

.payment__filterStatusItem > small {
  line-height: 1.6;
  padding: 0px 10px;
  margin-left: 8px;
  border-radius: 10px;
  background-color: rgb(211, 230, 243);
  font-size: 13px;
  font-weight: 500;
}
.payment__filterDate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  gap: 24px;
}
.payment__filterDate > * {
  flex: 1;
}
.payment__filterDate > button {
  display: flex;
}
