.addButton {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 84px;
  line-height: 1.75;
  padding: 0.6rem 1.5rem;
  margin: 0px;
  border: 0px;
  outline: 0px;
  border-radius: 4px;
  box-shadow: none;
  background-color: rgb(36, 153, 239);
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  vertical-align: middle;
  appearance: none;
}

.addButton:hover {
  box-shadow: rgba(95, 116, 141, 0.03) 0px 2px 5px -1px,
    rgba(95, 116, 141, 0.04) 0px 4px 10px 0px,
    rgba(95, 116, 141, 0.08) 0px 1px 22px 0px;
  background-color: rgb(25, 107, 167);
  text-decoration: none;
}
.addButtonIcon {
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}
