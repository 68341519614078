.input {
  position: relative;
  min-width: 48%;
  flex: 1;
}

.input__data {
  width: 100%;
  min-width: 0px;
  line-height: 1.4375em;
  padding: 18px;
  border: 1px solid rgb(140, 163, 186);
  outline: 0px;
  border-radius: 4px;
  background: none;
  color: rgb(18, 31, 67);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
  cursor: text;
}
.input__data:hover {
  border: 1px solid rgb(95, 116, 141);
}

.input__data:focus {
  border: 1px solid rgb(36, 153, 239);
}

.input__data:focus.required {
  border: 1px solid rgb(39, 206, 136);
}

.input__label {
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  z-index: 1;
  line-height: 1.4375em;
  padding: 0 10px;
  background-color: #fff;
  color: rgb(95, 116, 141);
  font-size: 1rem;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translate(14px, 18px) scale(1);
  transform-origin: left top;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 200ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.input__data:focus + .input__label,
.input__data:valid + .input__label {
  padding: 0 10px 0 20px;
  color: rgb(36, 153, 239);
  transform: translate(5px, -8px) scale(0.7);
}

.input__data:focus + .input__label.required {
  color: rgb(39, 206, 136);
}

.input__data::placeholder {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input__data:focus::placeholder {
  opacity: 1;
  transition: opacity 200ms cubic-bezier(0, 0, 0.2, 1) 200ms;
}

.input__label.has-value {
  padding: 0 10px 0 20px;
  color: rgb(36, 153, 239);
  transform: translate(5px, -8px) scale(0.7);
}
/* CheckBox */

.checkInput {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 94%;
  line-height: 1.4375em;
  padding: 18px;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.checkbox {
  position: relative;
  min-width: 60px;
  height: 26px;
  outline: 0;
  border-radius: 20px;
  background-color: rgb(222, 230, 243);
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  appearance: none;
}

.checkbox:checked {
  background-color: rgb(36, 153, 239, 0.5);
}

.checkbox::before {
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  width: 28px;
  height: 28px;
  border-radius: 20px;
  background-color: rgb(200, 200, 239);
  transform: scale(1.1);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.checkbox:hover::before {
  box-shadow: 0 0 0 10px rgb(200, 200, 239, 0.5);
}

.checkbox:checked::before {
  left: 32px;
  background-color: rgb(36, 153, 239);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.checkbox:hover:checked::before {
  box-shadow: 0 0 0 0 rgb(200, 200, 239, 0.5);
}

.inputCheckbox__label {
  line-height: 1.4375em;
  padding: 0 10px;
  background-color: #fff;
  color: rgb(95, 116, 141);
  font-size: 1rem;
  font-weight: 400;
  white-space: nowrap;
  cursor: pointer;
  pointer-events: none;
}

/* InputSelect  */

.inputSelect {
  position: relative;
  z-index: 1;
  width: 100%;
}
.inputSelect__selectButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 0px;
  line-height: 1.4375em;
  padding: 18px;
  border: 1px solid rgb(140, 163, 186);
  outline: 0px;
  border-radius: 4px;
  background: none;
  color: rgb(18, 31, 67);
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.inputSelect__content {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: -1;
  height: 355px;
  padding: 20px;
  border: 1px solid rgb(140, 163, 186);
  border-radius: 4px;
  background-color: #fff;
  opacity: 0;
  transform: scale(0);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
}
.inputSelect__content.active {
  opacity: 1;
  transform: scale(1);
  pointer-events: auto;
}
.inputSelect__content.up {
  top: 70px;
}

.inputSelect__content.down {
  top: calc(-355px - 10px);
}

.inputSelect__search {
  position: relative;
}

.searchSelect__input {
  width: 100%;
  height: 53px;
  padding: 0 15px 0 24px;
  border: 1px solid rgb(140, 163, 186);
  outline: 0;
  border-radius: 4px;
  font-size: 17px;
}

.searchSelect__icon {
  position: absolute;
  line-height: 53px;
  color: rgb(153, 153, 153);
  transform: rotate(0deg) scale(0.8);
}

.searchSelect__icon.active {
  transform: rotate(-180deg) scale(0.8);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.searchSelect__icon--prefix {
  top: 14px;
  left: 2px;
}

.searchSelect__icon--postfix {
  top: 17px;
  right: 15px;
  cursor: pointer;
}
.inputSelect__list {
  max-height: 250px;
  margin-top: 10px;
  list-style: none;
  overflow-y: auto;
}

.inputSelect__itemList {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 50px;
  padding: 0 13px;
  border-radius: 5px;
  font-size: 21px;
  cursor: pointer;
}

.inputSelect__list::-webkit-scrollbar {
  width: 7px;
}

.inputSelect__list::-webkit-scrollbar-track {
  border-radius: 25px;
  background-color: #f1f1f1;
}

.inputSelect__list::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: #ccc;
}
.inputSelect__itemList:hover {
  background-color: #f2f2f2;
}

/* Textarea */

.textarea {
  min-width: 100%;
  max-width: 100%;
  flex: 1;
  min-height: 5em;
}

/* Radio */
.radio {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
  padding: 10px;
}

.radio__label {
  position: relative;
  line-height: 1.5;
  padding-left: 30px;
  color: rgb(18, 31, 67);
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.radio__label--list {
  width: 100%;
}
.radio__input {
  display: none;
}
.radio__span {
  position: absolute;
  top: 2px;
  left: 0;
  z-index: 1;
  display: block;
  width: 20px;
  height: 20px;
  border: 3px solid rgb(95, 116, 141);
  border-radius: 50%;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.radio__span:hover {
  box-shadow: 0px 0px 0px 10px rgba(36, 153, 239, 0.15);
}
.radio__span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(36, 153, 239);
  transform: translate(-50%, -50%) scale(0);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.radio__input:checked ~ .radio__span::after {
  transform: translate(-50%, -50%) scale(1);
}

.radio__input:checked ~ .radio__span {
  border: 3px solid rgba(95, 116, 141);
}

.inputRadio__label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 20px;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.inputRadio__label:hover {
  border-radius: 4px;
  background-color: rgba(36, 153, 239, 0.2);
}

.inputRadio__element {
  width: 100%;
  min-width: 200px;
  flex: 1;
}
