.errorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.4375em;
  padding: 10px;
  margin-bottom: 1.5rem;
  outline: 0px;
  border-radius: 4px;
  background-color: rgb(255, 0, 0);
  color: rgb(255, 255, 255);
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: inherit;
}
.errorMessage__icon {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}

.inputError {
  height: 1rem;
  line-height: 1rem;
  padding: 0 20px;
  color: rgb(255, 0, 0);
  font-size: 0.7rem;
}

.errorInput {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 1rem;
  margin: 0.2rem 0 0 1rem;
  font-size: 0.8rem;
  font-weight: 400;
}

.errorInput__icon {
  height: 1rem;
  margin-right: 0.5rem;
  color: rgb(255, 0, 0);
}
.errorInput__message {
  color: rgb(255, 0, 0);
}
