.customerInfo {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.customerInfo__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 3;
  gap: 20px;
  flex-wrap: wrap;
}

.customerInfo__right {
  flex: 1;
}
.infoHeader__link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
.customerInfo__card {
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1;
  gap: 20px;
  padding: 48px 24px 24px 24px;
  padding: 24px;
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(95, 116, 141, 0.03) 0px 2px 1px -1px,
    rgba(95, 116, 141, 0.04) 0px 1px 1px 0px,
    rgba(95, 116, 141, 0.08) 0px 1px 3px 0px;
  background-color: rgb(255, 255, 255);
  flex-wrap: wrap;
}
.customerInfo__headerContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.customerInfo__header {
  min-width: 100%;
  line-height: 1;
  color: rgb(18, 31, 67);
  font-size: 18px;
  font-weight: 600;
  text-size-adjust: 100%;
}
.customerInfo__list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
  margin-top: 0px;
  color: rgb(18, 31, 67);
}
.customerInfo__data {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  line-height: 1;
  gap: 10px;
  padding: 10px;
  color: rgb(95, 116, 141);
  font-size: 16px;
  font-weight: 600;
}
