.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background-color: #b1c9dc;
}
.empty {
  height: 12px;
  margin: 10px auto;
}
.login__wrapper {
  width: 500px;
  min-width: 400px;
  max-width: 550px;
  padding: 32px;
  border-radius: 4px;
  background-color: #fff;
  text-align: center;
}

.login__title {
  width: 100%;
  margin-top: 16px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
}

.login__container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 40px;
}
.login__form {
  display: block;
}

.login__inputContainer {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid rgb(140, 163, 186);
  vertical-align: top;
  border-radius: 4px;
}
.login__inputContainer:hover {
  border: 1px solid rgb(95, 116, 141);
}

.login__label {
  position: absolute;
  top: 0px;
  left: 0px;
  overflow: hidden;
  z-index: 1;
  display: block;
  max-width: calc(100% - 24px);
  line-height: 1.4375em;
  padding: 0px;
  color: rgb(95, 116, 141);
  font-size: 1rem;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  transform: translate(14px, 16px) scale(1);
  transform-origin: left top;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.login__inputWrapper {
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  width: 100%;
  line-height: 1.4375em;
  border-radius: 4px;
  color: rgb(18, 31, 67);
  font-family: Montserrat, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  cursor: text;
}

.login__input,
.login__textarea {
  box-sizing: content-box;
  display: block;
  width: 100%;
  min-width: 0px;
  height: 1.4375em;
  padding: 16.5px 14px;
  margin: 0px;
  border: 0px;
  background: none;
  color: currentcolor;
  color: rgb(18, 31, 67);
  font-weight: 500;
  font: inherit;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
  animation-name: mui-auto-fill-cancel;
  animation-duration: 10ms;
}

.login__button {
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 84px;
  line-height: 1.75;
  padding: 0.6rem 1.5rem;
  margin: 0px;
  border: 0px;
  outline: 0px;
  border-radius: 4px;
  box-shadow: none;
  background-color: rgb(36, 153, 239);
  color: rgb(255, 255, 255);
  font-family: Montserrat, sans-serif;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  vertical-align: middle;
  appearance: none;
}

.login__button:hover {
  background-color: rgb(25, 107, 167);
  text-decoration: none;
}
