.sidebar {
  flex: 1;
  min-height: 100vh;
  border-right: 0.5px solid rgb(230, 227, 227);
  background-color: rgb(255, 255, 255);
}
.sidebar__top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}
.sidebar__logo {
  color: rgb(100, 57, 255);
  font-size: 20px;
  font-weight: bold;
}

.sidebar__center {
  padding-left: 10px;
}

.sidebar__list {
  list-style: none;
}
.sidebar__title {
  margin-top: 15px;
  margin-bottom: 5px;
  color: rgb(153, 153, 153);
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
}

.sidebar__item {
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 4px;
  cursor: pointer;
}
.sidebar__item:hover {
  background-color: rgb(236, 232, 255);
}

.sidebar__icon {
  margin-right: 10px;
  color: rgb(116, 81, 248);
  font-size: 18px;
}

.sidebar__name {
  color: rgb(136, 136, 136);
  font-size: 13px;
  font-weight: 600;
}

.sidebar__link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: inherit;
  font-size: inherit;
  text-decoration: none;
}
