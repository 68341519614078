.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 90%;
  height: 100%;
  gap: 16px;
  padding: 32px;
  margin: 0 auto ;
  border-radius: 4px;
  /* background-color: rgb(255, 255, 255); */
  text-align: center;
}
