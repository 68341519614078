.button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 1.5rem;
  margin: 0px;
  border: 0px;
  outline: 0px;
  border-radius: 4px;
  background-color: rgb(36, 153, 239);
  color: rgb(255, 255, 255);
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-tap-highlight-color: transparent;
}

.button:hover {
  box-shadow: rgba(95, 116, 141, 0.03) 0px 2px 5px -1px,
    rgba(95, 116, 141, 0.04) 0px 4px 10px 0px,
    rgba(95, 116, 141, 0.08) 0px 1px 22px 0px;
  background-color: rgb(25, 107, 167);
  text-decoration: none;
}

.buttonIcon {
  display: inherit;
  margin-right: 8px;
  margin-left: -4px;
}

.button.cancel {
  border: 1px solid rgb(211, 230, 243);
  background-color: transparent;
  color: rgb(18, 31, 67);
}

.button.delete {
  border: 0px;
  background-color: rgb(255, 49, 111);
  color: rgb(255, 255, 255);
}

.button.delete:hover {
  background-color: rgb(202, 26, 79);
}

.button.positiv {
  border: 0px;
  background-color: rgb(39, 206, 136);
  color: rgb(255, 255, 255);
}

.button:hover.positiv {
  border: 0px;
  background-color: rgb(24, 111, 75);
  color: rgb(255, 255, 255);
}
.button:disabled {
  border: 0px;
  background-color: rgb(128, 128, 128);
}
.button:disabled:hover {
  border: 0px;
  background-color: rgb(128, 128, 128);
  cursor: auto;
}
