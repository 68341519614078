.modal {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 95vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal__frame {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30%;
  max-width: 800px;
  min-height: 100px;
  gap: 10px;
  padding: 20px;
  background-color: rgb(255, 255, 255);
}

.modal__button {
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  border: 10px solid rgb(255, 255, 255);
  border-radius: 50%;
  box-shadow: 0 0 0 2px rgb(0, 128, 0);
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
}

.addForm input,
.addForm textarea,
.addForm select {
  display: block;
  margin: 5px;
}
