.basicTable__headRow {
  display: table-row;
  outline: 0px;
  background-color: rgba(36, 153, 239, 0.08);
  color: inherit;
  vertical-align: middle;
}

.basicTable__headRowHeader {
  display: table-cell;
  line-height: 1.5rem;
  padding: 16px;
  border-bottom: 0px;
  color: rgb(140, 163, 186);
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  transition: color 0.3s ease 0s;
  vertical-align: inherit;
}
.basicTable__headRowHeaderEdit {
  text-align: center;
}

@media only screen and (max-width: 1366px) {
.basicTable__headRowHeader {
  line-height: 1.5rem;
    padding: 16px 2px;
    font-size: 11px;
}
}