.carrier {
  position: relative;
}

.carrier__listTitle {
  margin-bottom: 15px;
  color: rgb(128, 128, 128);
  font-weight: 500;
}

.table {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.table .row {
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 1200px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(0, 0, 0);
}

.table .row.search {
  display: flex;
  justify-content: stretch;
  align-items: center;
  width: 1200px;
  padding: 0;
  border-bottom: 1px solid rgb(0, 0, 0);
}

.table .data {
  display: flex;
  justify-content: stretch;
  align-items: center;
  flex: 3;
}

.table .data td,
.table .data th {
  flex: 1;
  text-align: left;
}

.table .actions {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex: 1;
}
