.pageContainer {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 52px);
  padding: 5px;
  background-color: rgb(243, 244, 249);
}

.wrapper{
overflow-y: scroll;
}

.wrapper::-webkit-scrollbar {
  width: 10px;
}
.wrapper::-webkit-scrollbar-track {
  border-radius: 25px;
  background-color: #f1f1f1;
}
.wrapper::-webkit-scrollbar-thumb {
  border-radius: 25px;
  background-color: #ccc;
}
