.infoHeader {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(95, 116, 141, 0.03) 0px 2px 1px -1px,
    rgba(95, 116, 141, 0.04) 0px 1px 1px 0px,
    rgba(95, 116, 141, 0.08) 0px 1px 3px 0px;
  background-color: rgb(255, 255, 255);
  flex-wrap: wrap;
}

.infoHeader__up {
  width: 100%;
  height: 10px;
}

.infoHeader__bottom {
  width: 100%;
  padding: 48px 24px 24px 24px;
}
.infoHeader__header {
  color: rgb(18, 31, 67);
  font-family: Montserrat, sans-serif;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  text-size-adjust: 100%;
}
.infoHeader__location {
  display: flex;
  justify-content: center;
  align-items: space-between;
  gap: 20px;
  padding-top: 8px;
  margin: auto;
  flex-wrap: wrap;
}

.infoHeader__element {
  display: flex;
  align-items: center;
}

.infoHeader__icon {
  display: inline-block;
  width: 1em;
  height: 10px;
  margin-right: 8px;
  color: rgb(95, 116, 141);
  font-size: 12px;
  user-select: none;
  fill: currentColor;
  flex-shrink: 0;
}

.infoHeader__info {
  display: block;
  line-height: 1.65;
  color: rgb(95, 116, 141);
  font-size: 18px;
  font-weight: 500;
  text-size-adjust: 100%;
}

.infoHeader__stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
  padding-top: 32px;
  margin: auto;
  flex-wrap: wrap;
}
.infoHeader__statsCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  padding: 20px;
  margin-bottom: 0;
  border: 1px solid rgb(211, 230, 243);
  border-radius: 8px;
}
.infoHeader__statsHeader {
  line-height: 1;
  color: rgb(36, 153, 239);
  font-family: Montserrat, sans-serif;
  font-size: 16px;
  font-weight: 600;
}

.infoHeader__statsHeader--red {
  color: rgb(39, 206, 136);
}
.infoHeader__statsDesc {
  line-height: 1.65;
  margin: 6px 0px 0px;
  color: rgb(95, 116, 141);
  font-size: 15px;
  font-weight: 500;
}

.infoNav__list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 80px;
}
.infoNav__itemList {
  position: relative;
  overflow: hidden;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: auto;
  max-width: 360px;
  min-height: 48px;
  line-height: 1.25;
  padding: 0px 8px;
  border-bottom: 2px solid rgb(255, 255, 255);
  background-color: transparent;
  color: rgb(95, 116, 141);
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  text-transform: none;
  white-space: normal;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  flex-shrink: 0;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.infoNav__itemList--active {
  border-bottom: 2px solid rgb(36, 153, 239);
  color: rgb(36, 153, 239);
}
