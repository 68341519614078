.searchBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0px;
  flex-wrap: wrap;
}

.searchBar__inputContainer {
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  width: 100%;
  max-width: 350px;
  height: 45px;
  line-height: 1.4375em;
  padding: 0px 1rem;
  border: 1px solid rgb(177, 201, 220);
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  color: rgb(18, 31, 67);
  font-size: 12px;
  font-weight: 600;
  cursor: text;
}

.searchBar__icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 8px;
  color: rgb(140, 163, 186);
  font-size: 18px;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  fill: currentcolor;
  flex-shrink: 0;
}

.searchBar__input {
  box-sizing: content-box;
  display: block;
  width: 100%;
  min-width: 0px;
  height: 1.4375em;
  padding: 4px 0px 5px;
  margin: 0px;
  border: 0px;
  outline: 0px;
  background: none;
  color: currentcolor;
  font: inherit;
  letter-spacing: inherit;
  -webkit-tap-highlight-color: transparent;
}

.searchBar__input:hover {
  outline: 0px;
}
