.haederSide {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.haederSide__left {
  display: flex;
  align-items: center;
  gap: 4px;
}

.haederSide__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
  border-radius: 5px;
  background-color: rgb(229, 243, 253);
  color: rgb(36, 153, 239);
}

.haederSide__title {
  line-height: 1;
  font-size: 14px;
  font-weight: 600;
}

.haederSide__titlebuttonsContainer {
  display: flex;
  gap: 20px;
}
