.fieldset {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  flex: 1;
  gap: 20px;
  padding: 34px 24px 12px 24px;
  border: 0;
  border-radius: 4px;
  box-shadow: rgba(95, 116, 141, 0.03) 0px 2px 1px -1px,
    rgba(95, 116, 141, 0.04) 0px 1px 1px 0px,
    rgba(95, 116, 141, 0.08) 0px 1px 3px 0px;
  background-color: rgb(255, 255, 255);
  flex-wrap: wrap;
}

/* .fieldset.row {
  justify-content: space-between;
  align-items: center;
} */

/*.fieldset.column {
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
} */

.legend {
  position: absolute;
  top: 10px;
  left: 10px;
  line-height: 1;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}
